var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-form-item',{attrs:{"hidden":"","label":"roleId"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['roleId',{ initialValue:'0' }]),expression:"['roleId',{ initialValue:'0' }]"}],attrs:{"name":"roleId"}})],1),_c('a-form-item',{attrs:{"label":"角色名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'rolename',
          {rules: [{ required: true, message: '请输入角色名称' }]}
        ]),expression:"[\n          'rolename',\n          {rules: [{ required: true, message: '请输入角色名称' }]}\n        ]"}],attrs:{"name":"rolename","placeholder":"请输入角色名称"}})],1),_c('a-form-item',{attrs:{"label":"角色描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description',{ initialValue:''}]),expression:"['description',{ initialValue:''}]"}]})],1),_c('a-form-item',{attrs:{"label":"排序"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'listorder',
          { initialValue:'100'},
          {rules: [{ required: true, message: '请输入排序' }]}
        ]),expression:"[\n          'listorder',\n          { initialValue:'100'},\n          {rules: [{ required: true, message: '请输入排序' }]}\n        ]"}],staticStyle:{"width":"100%"},attrs:{"max":100,"min":0,"name":"listorder"}})],1),_c('a-form-item',{attrs:{"required":false,"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status', { initialValue: 1 }]),expression:"['status', { initialValue: 1 }]"}],attrs:{"name":"status"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("启用")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("禁用")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }