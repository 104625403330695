<template>
  <div>
    <a-form
      :form="form"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol">
      <a-form-item
        hidden
        label="roleId"
      >
        <a-input
          v-decorator="['roleId',{ initialValue:'0' }]"
          name="roleId"></a-input>
      </a-form-item>
      <a-form-item
        label="角色名称"
      >
        <a-input
          v-decorator="[
            'rolename',
            {rules: [{ required: true, message: '请输入角色名称' }]}
          ]"
          name="rolename"
          placeholder="请输入角色名称"/>
      </a-form-item>
      <a-form-item
        label="角色描述">
        <a-textarea v-decorator="['description',{ initialValue:''}]"></a-textarea>
      </a-form-item>

      <a-form-item
        label="排序"
      >
        <a-input-number
          v-decorator="[
            'listorder',
            { initialValue:'100'},
            {rules: [{ required: true, message: '请输入排序' }]}
          ]"
          :max="100"
          :min="0"
          name="listorder"
          style="width: 100%"/>
      </a-form-item>
      <a-form-item
        :required="false"
        label="状态"
      >
        <a-radio-group v-decorator="['status', { initialValue: 1 }]" name="status">
          <a-radio :value="1">启用</a-radio>
          <a-radio :value="2">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>

export default {
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: this.$form.createForm(this)
    }
  }
}
</script>
